import { Typography } from 'antd';
import { graphql, PageProps } from 'gatsby';
import {
  GatsbyImage,
  getImage,
  getSrc,
  ImageDataLike,
} from 'gatsby-plugin-image';
import React from 'react';

import Container from '@/components/Container';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import { ArticlesType } from '@/types';

const ArticlesTemplate: React.FC<
  PageProps & {
    data: { file: ImageDataLike; articlesJson: ArticlesType };
  }
> = ({ uri, data: { file, articlesJson } }) => {
  const image = getImage(file);
  const imageSrc = getSrc(file);

  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      <SEO
        title={articlesJson.title}
        uri={uri}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        description={articlesJson.content[0].text!}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        image={imageSrc!}
      />
      <Container>
        <Typography.Title className="text-center mt-4 mb-8">
          {articlesJson.title}
        </Typography.Title>
        <article
          className="prose prose-xl mx-auto pb-8"
          style={{ maxWidth: 960 }}
        >
          {image ? (
            <GatsbyImage
              image={image}
              className="float-right"
              alt={articlesJson.title}
              style={{
                objectFit: 'cover',
                paddingLeft: 32,
                marginTop: -24,
              }}
            />
          ) : null}
          {articlesJson.content.map((i, idx) => (
            <Typography.Paragraph key={idx}>{i.text}</Typography.Paragraph>
          ))}
        </article>
      </Container>
    </Layout>
  );
};

export default ArticlesTemplate;

export const pageQuery = graphql`
  query Article($imageName: String, $title: String) {
    file(name: { eq: $imageName }, sourceInstanceName: { eq: "articles" }) {
      childImageSharp {
        gatsbyImageData(height: 400)
      }
    }
    articlesJson(title: { eq: $title }) {
      imageName
      title
      slug
      content {
        text
      }
    }
  }
`;
